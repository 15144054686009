import axios, { AxiosResponse } from 'axios';

import { GLOBAL_VARS } from "../config/global-vars.config";

export interface User {
  id: string;
  username: string;
  pass?: string;
  creado?: string;
}

// Configuración de Axios
axios.defaults.baseURL = GLOBAL_VARS.API;

axios.interceptors.request.use((config) => {
  const token = sessionStorage.getItem('token');
  config.headers['Authorization'] = token;

  return config;
});

class UsersService {
  static async listar() {
    try {
      const response = await axios.get<User[]>(`/users`);

      return response.data;
    } catch (e) {
      console.error(e);
      return [];
    }
  };

  static async crear(user: User) {
    try {
      return await axios.post(`/users`, user, { headers: {} });
    } catch (e: any) {
      console.error(e);
      return e.response;
    }
  }

  static async eliminar(id: string) {
    try {
      const response = await axios.delete(`/users/${id}`);

      if (response.status === 204) {
        return true;
      } else {
        return false;
      }
    } catch (e: any) {
      console.error(e);
    }
  }

  static async actualizar(user: User) {
    try {
      const response = await axios.patch(`/users/${user.id}`, user, { headers: {} });

      if (response.status === 201) {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      console.error(e)
    }
  }
}

export default UsersService;