import { Link } from "react-router-dom";

import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Paper, TableFooter, Stack, Pagination, Dialog, DialogTitle, DialogContent, TextField, DialogActions, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, Select, MenuItem, FormHelperText } from "@mui/material";

import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import PreviewRoundedIcon from '@mui/icons-material/PreviewRounded';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';

import { useRoleCheck, getUserId } from '../hooks/use-role-check.hook';
import Page from '../components/page/page.component';

import './users.page.css';
import UsersService, { User } from "../services/users.service";
import { useEffect, useState } from "react";
import { rutFormatter, formatRutForDisplay, formatRutForInput, rutValido, insertDash } from '../helpers/validations-form.helper';

const breadcrumbs = [
  <Link key="1" to="/">Inicio</Link>,
  <Typography key="2" color="text.primary">Usuarios</Typography>
];

interface Props {
  rowsPerPage: number;
  page: number;
};

const Content = ({rowsPerPage, page}: Props) => {
  const [refresh, setRefresh] = useState<number>(0);
  const [usuarioNuevo, setUsuarioNuevo] = useState(false);
  const [openDialogCrearUsuario, setOpenDialogCrearUsuario] = useState(false);
  const [openDialogModificarUsuario, setOpenDialogModificarUsuario] = useState(false);
  const [openDialogVerDetalle, setOpenDialogVerDetalle] = useState(false);
  const [usuarios, setUsuarios] = useState<User[]>([]);
  const [usuario, setUsuario] = useState<User | undefined>();
  const [createId, setCreateId] = useState<string>('');

  const [rut, setRut] = useState<string>('');
  const [rutError, setRutError] = useState<boolean>(false);
  const [rutErrorMessage, setRutErrorMessage] = useState<string>('');
  const [clave, setClave] = useState<string>('');
  const [claveError, setClaveError] = useState<boolean>(false);
  const [claveErrorMessage, setClaveErrorMessage] = useState<string>('');
  const [username, setUsername] = useState<string>('');
  const [usernameError, setUsernameError] = useState<boolean>(false);
  const [usernameErrorMessage, setUsernameErrorMessage] = useState<string>('');

  const [pass, setPass] = useState<string>('');
  const [deleteId, setDeleteId] = useState<string>('');
  const [updateId, setUpdateId] = useState<string>('');

  const fnOpenDialogCrearUsuario = () => {
    setUsuarioNuevo(true);
    setOpenDialogCrearUsuario(true);
  };
  
  const fnOpenDialogModificarUsuario = (id: string, username: string) => {
    setUsuarioNuevo(false);
    setRut(id);
    setUsername(username);
    setOpenDialogModificarUsuario(true);
  };
  
  const fnOpenDialogVerDetalleUsuario = (id: string, username: string) => {
    setRut(id);
    setUsername(username);
    setOpenDialogVerDetalle(true);
  };

  const fnCloseAllDialogs = () => {
    setOpenDialogCrearUsuario(false);
    setOpenDialogModificarUsuario(false);
    setOpenDialogVerDetalle(false);
    setCreateId('');
    fnCleanFormAddUser();
  };

  const fnCleanFormAddUser = () => {
    setRut('');
    setRutError(false);
    setClave('');
    setClaveError(false);
    setUsername('');
    setUsernameError(false);
  }

  const fnCrearUsuario = () => {
    setUpdateId('');
    setDeleteId('');

    if (!rutError) {
      const usuario: User = {
        id: rutFormatter(rut),
        username: username,
        pass: clave,
        creado: new Date().toISOString()
      };
      
      setUsuario(usuario);
      fnCloseAllDialogs();
    } else {
      // TODO: Implementar un mensaje de error.
      setRutError(true);
    }
  };
  
  const fnActualizarUsuario = () => {
    setCreateId('');
    setDeleteId('');

    setUpdateId(rut);

    const usuario: User = {
      id: rut,
      username: username,
      pass: pass,
      creado: new Date().toISOString()
    };
    
    setUsuario(usuario);
    fnCloseAllDialogs();
  };

  const fnHandleRutChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    setRut(inputValue);

    if (inputValue === '') {
      setRutError(true);
      setRutErrorMessage('Campo Obligatorio');
    } else if (!rutValido(inputValue)) {
      setRutError(true);
      setRutErrorMessage('Rut Inválido');
    } else {
      setRutError(false);
      setRutErrorMessage('');
    }
  };

  const fnHandleClaveChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    setClave(inputValue);
    
    if (inputValue === '') {
      setClaveError(true);
      setClaveErrorMessage('Campo Obligatorio');
    } else {
      setClaveError(false);
      setClaveErrorMessage('');
    }
  };

  const fnHandleUsernameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    setUsername(inputValue);
    
    if (inputValue === '') {
      setUsernameError(true);
      setUsernameErrorMessage('Campo Obligatorio');
    } else {
      setUsernameError(false);
      setUsernameErrorMessage('');
    }
  };

  useEffect(() => {
    if (deleteId !== '') {
      (async () => {
        const response = await UsersService.eliminar(deleteId);
        setDeleteId('');
        setRefresh((refresh) => refresh + 1);
      })();
    }

    if (typeof usuario === 'object') {
      if (usuarioNuevo) {
        (async () => {
          const response = await UsersService.crear(usuario);
          // console.log(response);
          if (response.status === 201) {
            // console.log('Usuario creado correctamente.');
          }
          
          if (response.status === 409) {
            // console.log('Usuario duplicado.');
          }

          if (response.status === 500) {
            // console.log('Error de servidor.');
          }

          setRefresh((refresh) => refresh + 1);
        })();

        setCreateId('');
        setUsername('');
        setPass('');
        setUsuarioNuevo(false);
      }
      
      if (updateId !== '') {
        (async () => {
          const response = await UsersService.actualizar(usuario);
          setRefresh((refresh) => refresh + 1);
        })();

        setUpdateId('');
      }

      setUsuario(undefined);
    }
  }, [usuario, deleteId]);

  useEffect(() => {
    (async () => {
      const data = await UsersService.listar();
      setUsuarios(data);
    })();
  }, [refresh]);

  return (
    <section>
      <Button variant="contained" size="small" startIcon={<AddCircleRoundedIcon />} onClick={fnOpenDialogCrearUsuario} >Crear</Button>
      <Dialog open={openDialogCrearUsuario} onClose={fnCloseAllDialogs}>
        <DialogTitle>CREAR USUARIO</DialogTitle>
        <DialogContent>
          <FormControl variant="standard" sx={{ m: 1, minWidth: 300 }}>
            <TextField
              autoFocus
              margin="dense"
              id="rut"
              label="Rut"
              type="text"
              fullWidth
              variant="standard"
              value={formatRutForDisplay(rut)}
              onChange={fnHandleRutChange}
              inputProps={{ maxLength: 12 }}
              error={rutError} // Establece el estado de error
              helperText={rutError ? rutErrorMessage : ''} // Muestra un mensaje de ayuda si hay un error
              required // Marca el campo como obligatorio
            />
            <TextField
              autoFocus
              margin="dense"
              id="username"
              label="Username"
              type="text"
              fullWidth
              variant="standard"
              value={username}
              onChange={fnHandleUsernameChange}
              error={usernameError} // Establece el estado de error
              helperText={usernameError ? usernameErrorMessage : ''} // Muestra un mensaje de ayuda si hay un error
              required // Marca el campo como obligatorio
            />
            <TextField
              autoFocus
              margin="dense"
              id="clave"
              label="Clave"
              type="text"
              fullWidth
              variant="standard"
              value={clave}
              onChange={fnHandleClaveChange}
              error={claveError} // Establece el estado de error
              helperText={claveError ? claveErrorMessage : ''} // Muestra un mensaje de ayuda si hay un error
              required // Marca el campo como obligatorio
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={fnCrearUsuario}>Crear</Button>
          <Button onClick={fnCloseAllDialogs}>Cancelar</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openDialogModificarUsuario} onClose={fnCloseAllDialogs}>
        <DialogTitle>MODIFICAR USUARIO</DialogTitle>
        <DialogContent>
          <FormControl variant="standard" sx={{ m: 1, minWidth: 300 }}>
            <TextField
              autoFocus
              margin="dense"
              id="rut"
              label="Rut"
              type="text"
              fullWidth
              variant="standard"
              InputProps={{
                readOnly: true,
              }}
              value={rut}
            />
            <TextField
              autoFocus
              margin="dense"
              id="username"
              label="Username"
              type="text"
              fullWidth
              variant="standard"
              value={username}
              onChange={e => setUsername(e.target.value)}
            />
            <TextField
              autoFocus
              margin="dense"
              id="clave"
              label="Clave"
              type="text"
              fullWidth
              variant="standard"
              value={pass}
              onChange={e => setPass(e.target.value)}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={fnActualizarUsuario}>Guardar</Button>
          <Button onClick={fnCloseAllDialogs}>Cancelar</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openDialogVerDetalle} onClose={fnCloseAllDialogs}>
        <DialogTitle>INFORMACIÓN DE USUARIO</DialogTitle>
        <DialogContent>
          <FormControl variant="standard" sx={{ m: 1, minWidth: 300 }}>
            <TextField
              autoFocus
              margin="dense"
              id="rut"
              label="Rut"
              type="text"
              fullWidth
              variant="standard"
              InputProps={{
                readOnly: true,
              }}
              value={rut}
            />
            <TextField
              autoFocus
              margin="dense"
              id="username"
              label="Username"
              type="text"
              fullWidth
              variant="standard"
              InputProps={{
                readOnly: true,
              }}
              value={username}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={fnCloseAllDialogs}>Cerrar</Button>
        </DialogActions>
      </Dialog>
      <div className="container">
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>RUT</TableCell>
                <TableCell>USERNAME</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {usuarios.map((usuario) => (
                <TableRow key={usuario.id}>
                  <TableCell>{usuario.id}</TableCell>
                  <TableCell>{usuario.username}</TableCell>
                  <TableCell align="right">
                    <Button variant="contained" size="small" startIcon={<SettingsRoundedIcon />} onClick={() => fnOpenDialogModificarUsuario(usuario.id, usuario.username)}>Modificar</Button>&nbsp;
                    {/* <Button variant="contained" size="small" startIcon={<InfoRoundedIcon />} onClick={() => fnOpenDialogVerDetalleUsuario(usuario.id, usuario.username)}>Ver</Button>&nbsp; */}
                    {/* <Button variant="contained" size="small" startIcon={<PreviewRoundedIcon />} onClick={() => fnOpenDialogVerDetalleUsuario(usuario.id, usuario.username)}>Ver</Button>&nbsp; */}
                    <Button variant="contained" size="small" startIcon={<DeleteForeverRoundedIcon />} onClick={() => setDeleteId(usuario.id)}>Eliminar</Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell colSpan={3}>
                  <Stack spacing={2}>
                    <Pagination count={Math.ceil(usuarios.length / rowsPerPage)} page={page} onChange={(event, value) => setRefresh(value)} />
                  </Stack>
                </TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </div>
    </section>
  );
};

const Users = () => {
  const rowsPerPage = 10;
  const page = 1;
  return (
    <Page breadcrumbs={breadcrumbs} children={<Content rowsPerPage={rowsPerPage} page={page} />} />
  );
};

export default Users;