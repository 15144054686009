import { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { QRCodeCanvas } from 'qrcode.react';

import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Paper, TableFooter, Stack, Pagination, Dialog, DialogTitle, DialogContent, TextField, DialogActions, FormControl, InputLabel, Select, MenuItem, List, ListItem, ListItemText, Divider, Box, DialogContentText, IconButton } from "@mui/material";

import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import PreviewRoundedIcon from '@mui/icons-material/PreviewRounded';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';

import { RemoveCircleRounded as RemoveCircleRoundedIcon } from '@mui/icons-material';

import { useRoleCheck } from '../hooks/use-role-check.hook';
import Page from '../components/page/page.component';
import CardsService, { Answer, Card } from '../services/cards.service';
import TotemsService, { Totem } from '../services/totems.service';
import HeritagesService, { Heritage } from '../services/heritages.service';

import './cards.page.css';
import React from "react";
import { insertDash } from '../helpers/validations-form.helper';

const breadcrumbs = [
  <Link key="1" to="/">Inicio</Link>,
  <Typography key="2" color="text.primary">Tarjetas</Typography>
];

const Content = () => {
  let previousTotem = '';
  let rowBackgroundColor = '#ffffff'
  const [add, setAdd] = useState<boolean>(false);
  const [update, setUpdate] = useState<boolean>(false);
  const [remove, setRemove] = useState<boolean>(false);
  const [removeEstudiante, setRemoveEstudiante] = useState<string>('');
  const [refresh, setRefresh] = useState<number>(0);
  const [refreshEstudiantes, setRefreshEstudiantes] = useState<number>(0);
  const [openDialogCrearCurso, setOpenDialogCrearCurso] = useState(false);
  const [openDialogListarEstudiantes, setOpenDialogListarEstudiantes] = useState(false);
  const [estudiantes, setEstudiantes] = useState<Totem[]>([]);
  const [estudiantesCurso, setEstudiantesCurso] = useState<Totem[]>([]);
  const [curso, setCurso] = useState<Card | undefined>();
  const [temporada, setTemporada] = useState<number | undefined>();
  const [nivelId, setNivelId] = useState<string | undefined>();
  const [letra, setLetra] = useState<string | undefined>();
  const [url, setUrl] = useState<string>('');
  
  const [id, setId] = useState<number>(0);
  const [actionGetSelects, setActionGetSelects] = useState<boolean>(false);
  const [actionGet, setActionGet] = useState<boolean>(false);
  const [actionCreate, setActionCreate] = useState<boolean>(false);
  const [actionUpdate, setActionUpdate] = useState<boolean>(false);
  const [actionDelete, setActionDelete] = useState<boolean>(false);
  const [cards, setCards] = useState<Card[]>([]);
  const [card, setCard] = useState<Card>({id: 0});
  const [answers, setAnswers] = useState<Answer[]>([]);
  // const [card, setCard] = useState<Card | undefined>();
  const [totems, setTotems] = useState<Totem[]>([]);
  const [heritages, setHeritages] = useState<Heritage[]>([]);
  const [errors, setErrors] = useState({
    text: '',
    totem: '',
    heritage: '',
    videoLink: '',
  });
  const [openDialogCrear, setOpenDialogCrear] = useState(false);
  const [openDialogVerQR, setOpenDialogVerQR] = useState(false);
  const [openDialogVerDetalle, setOpenDialogVerDetalle] = useState(false);
  const [openDialogModificar, setOpenDialogModificar] = useState(false);
  const [openDialogEliminar, setOpenDialogEliminar] = useState(false);

  const qrCodeRef = useRef<HTMLDivElement>(null);

  const handleDownload = () => {
    if (qrCodeRef.current) {
      const canvas = qrCodeRef.current.querySelector('canvas');
      if (canvas) {
        const cardId = url.split('/').pop();
        const imageName = `qr-code-card-id-${cardId}.png`;
        const imgURL = canvas.toDataURL('image/png');  // Convertir el canvas a una URL de datos en PNG
        const link = document.createElement('a');
        link.href = imgURL;
        // link.download = 'qr-code.png';  // Nombre del archivo que se descargará
        link.download = imageName;  // Nombre del archivo que se descargará
        link.click();  // Simular el clic para iniciar la descarga
      }
    }
  };

  const fnOpenDialogCrear = () => {
    setActionGet(true);
    setActionGetSelects(true);
    setOpenDialogCrear(true);
  };

  const fnOpenDialogVerQR = (cardId: number) => {
    const baseURL = `${window.location.protocol}//${window.location.host}`;
    const fullURL = `${baseURL.replace('admin.','')}/video/${cardId}`;
    setUrl(fullURL);  // Guardamos la URL en el estado
    setOpenDialogVerQR(true);  // Abrimos el modal
  };

  const fnOpenDialogVerDetalle = (id: number) => {
    setId(id);
    setActionGet(true);
    setOpenDialogVerDetalle(true);
  };
  
  const fnOpenDialogModificar = (id: number) => {
    setId(id);
    setActionGet(true);
    setActionGetSelects(true);
    setOpenDialogModificar(true);
  };

  const fnErrorValidation = () => {
    let valid = true;
    let newErrors = {
      text: '',
      totem: '',
      heritage: '',
      videoLink: '',
    };
    
    if (card?.text === '') {
      newErrors.text = 'La pregunta es requerida';
      valid = false;
    } else {
      newErrors.text = '';
    }

    if (card?.totem?.id === undefined) {
      newErrors.totem = 'Debe seleccionar un totem';
      valid = false;
    } else {
      newErrors.totem = '';
    }

    if (card?.heritage?.id === undefined) {
      newErrors.heritage = 'Debe seleccionar un patrimonio';
      valid = false;
    } else {
      newErrors.heritage = '';
    }

    if (card?.videoLink === '') {
      newErrors.videoLink = 'El link del video es requerido';
      valid = false;
    } else {
      newErrors.text = '';
    }

    setErrors(newErrors);
    return valid;
  };

  // Función para agregar una nueva respuesta
  const fnAddAnswer = () => {
    const answer: Answer = {
      id: answers.length,
      text: ''
    };
    setAnswers(prevAnswers => [...prevAnswers, answer]);
  };

  // Función para modificar el texto de una respuesta específica
  const fnUpdateAnswer = (index: number, newText: string) => {
    const updatedAnswers = [...answers];
    updatedAnswers[index] = { ...updatedAnswers[index], text: newText };
    setAnswers(updatedAnswers);
  };

  // Función para eliminar una respuesta del array
  const fnDeleteAnswer = (index: number) => {
    const updatedAnswers = answers.filter((_, i) => i !== index);
    setAnswers(updatedAnswers);
  };

  const fnCrear = () => {
    if (fnErrorValidation()) {
      answers.forEach((answer, index) => {
        answer.index = index;
        answer.card = {
          id: card!.totem!.id
        }
      });
      setCard({ ...card!, answers: answers })
      setActionCreate(true);
    }
  };

  const fnModificar = () => {
    answers.forEach((answer, index) => {
      answer.index = index;
      answer.card = {
        id: card!.totem!.id
      }
    });
    setCard({ ...card!, answers: answers })
    setActionUpdate(true);
  };

  const fnDelete = (id: number) => {
    setId(id);
    setActionDelete(true);
  };

  // const fnOpenDialogListarEstudiantes = (cursoId: string | undefined) => {
  //   setId(cursoId);
  //   setRefreshEstudiantes((refreshEstudiantes) => refreshEstudiantes + 1);
  //   setOpenDialogListarEstudiantes(true);
  // };

  // const fnCrearCurso = () => {
  //   setAdd(true);

  //   const curso: Card = {
  //     id: temporada,
  //     texto: letra
  //   };
    
  //   setCurso(curso);
  //   fnCloseAllDialogs();
  // };

  // const fnGuardarCurso = () => {
  //   setUpdate(true);
  //   fnCloseAllDialogs();
  // };
  
  const fnCloseAllDialogs = () => {
    setOpenDialogCrear(false);
    setOpenDialogVerQR(false);
    setOpenDialogVerDetalle(false);
    setOpenDialogModificar(false);
  };

  // const fnRemove = (id: string | undefined) => {
  //   setRemove(true);
  //   setId(id);
  // };
  
  // const fnClean = () => {
  //   setAdd(false);
  //   setUpdate(false);
  //   setRemove(false);
  //   setId(undefined);
  //   setTemporada(undefined);
  //   setLetra(undefined);
  //   setCurso(undefined);
  // };

  // useEffect(() => {
  //   if (remove) {
  //     (async () => {
  //       const response = await CardsService.eliminar(id);
  //       setRefreshPage((refreshPage) => refreshPage + 1);
  //     })();

  //     fnClean();
  //   }

  //   if (add) {
  //     (async () => {
  //       const response = await CardsService.crear(curso);
  //       setRefreshPage((refreshPage) => refreshPage + 1);
  //     })();

  //     fnClean();
  //   }

  //   if (update) {
  //     (async () => {
  //       const response = await CardsService.actualizarEstudiantes(id!, estudiantesCurso);
  //       setRefreshPage((refreshPage) => refreshPage + 1);
  //     })();

  //     fnClean();
  //   }
  // }, [curso, id, update]);

  // const moverEstudiante = (sourceList: Totem[], targetList: Totem[], student: Totem) => {
  //   const updatedSourceList = sourceList.filter((s) => s !== student);
  //   const updatedTargetList = [...targetList, student];
  //   return [updatedSourceList, updatedTargetList];
  // };

  // const moverEstudianteCursoAColegio = (estudiante: Totem) => {
  //   const [listaActualizadaEstudiantesCurso, listaActualizadaEstudiantes] = moverEstudiante(estudiantesCurso, estudiantes, estudiante);
  //   setEstudiantesCurso(listaActualizadaEstudiantesCurso);
  //   setEstudiantes(listaActualizadaEstudiantes);
  // };

  // const moverEstudianteColegioACurso = (estudiante: Totem) => {
  //   const [listaActualizadaEstudiantes, listaActualizadaEstudiantesCurso] = moverEstudiante(estudiantes, estudiantesCurso, estudiante);
  //   setEstudiantes(listaActualizadaEstudiantes);
  //   setEstudiantesCurso(listaActualizadaEstudiantesCurso);
  // };

  // useEffect(() => {
  //   (async () => {
  //     const estudiantesDelCurso = await CardsService.obtenerAlumnosDelCurso(id);
  //     const estudiantes = await CardsService.obtenerAlumnosFueraDelCurso(id);
  //     setEstudiantesCurso(estudiantesDelCurso);
  //     setEstudiantes(estudiantes);
  //   })();
  // }, [refreshEstudiantes]);

  useEffect(() => {
    (async () => {
      if (actionCreate) {
        const data = await CardsService.crear(card!);
        // setCard(data);
        setActionCreate(false);
        setOpenDialogCrear(false);
        setRefresh((refresh) => refresh + 1);
      }
    })();
  }, [actionCreate]);

  useEffect(() => {
    (async () => {
      if (actionGet) {
        const dataTotems = await TotemsService.listar();
        setTotems(dataTotems);
        const dataHeritages = await HeritagesService.listar();
        setHeritages(dataHeritages);
        setActionGetSelects(false);
      }
    })();
  }, [actionGetSelects]);
  
  useEffect(() => {
    (async () => {
      if (actionGet) {
        const data = await CardsService.obtener(id);
        setCard(data);
        setAnswers(card?.answers || []);
        setActionGet(false);
        setId(0);
      }
    })();
  }, [actionGet]);

  useEffect(() => {
    (async () => {
      if (actionUpdate) {
        const data = await CardsService.actualizar(card!);
        // setTotem(data);
        setActionUpdate(false);
        setOpenDialogModificar(false);
        setRefresh((refresh) => refresh + 1);
      }
    })();
  }, [actionUpdate]);

  useEffect(() => {
    (async () => {
      if (actionDelete) {
        const data = await CardsService.eliminar(id);
        setActionDelete(false);
        setRefresh((refresh) => refresh + 1);
      }
    })();
  }, [actionDelete]);
  
  useEffect(() => {
    (async () => {
      const dataCards = await CardsService.listar();
      setCards(dataCards);
    })();
  }, [refresh]);

  return (
    <section>
      <Button variant="contained" size="small" startIcon={<AddCircleRoundedIcon />} onClick={fnOpenDialogCrear} >Crear</Button>
      
      <Dialog open={openDialogVerDetalle} onClose={fnCloseAllDialogs}>
        <DialogTitle>DETALLES</DialogTitle>
        <DialogContent>
          {card && card.id > 0 ? (
            <FormControl variant="standard" sx={{ m: 1, minWidth: 400 }}>
              <TextField
                disabled
                id="detail-id"
                label="ID"
                value={card.id}
                className="disabled-textfield"
              /><br/>
              <TextField
                disabled
                id="detail-text"
                label="PREGUNTA DE LA TARJETA"
                value={card.text}
                className="disabled-textfield"
                multiline
              /><br/>
              {card.answers && card.answers.map((answer, index) => (
                <React.Fragment key={`answer-fragment-${index}`}>
                  <TextField
                    key={`answer-${index}`}
                    disabled
                    id={`detail-answer-${index}`}
                    label={`RESPUESTA ${index + 1}`}
                    value={answer.text}
                    className="disabled-textfield"
                    multiline
                  /><br />
                </React.Fragment>
              ))}
              <TextField
                disabled
                id="detail-totem"
                label="TOTEM DE LA TARJETA"
                value={card.totem!.name}
                className="disabled-textfield"
                multiline
              /><br/>
              <TextField
                disabled
                id="detail-heritage"
                label="PATRIMONIO DE LA TARJETA"
                value={card.heritage!.name}
                className="disabled-textfield"
                multiline
              /><br/>
              <TextField
                disabled
                id="detail-qr-link"
                label="LINK DEL VIDEO"
                value={card.videoLink}
                className="disabled-textfield"
                multiline
              />
            </FormControl>
          ) : (
            <DialogContentText>
              Cargando...
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={fnCloseAllDialogs}>Cancelar</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openDialogCrear} onClose={fnCloseAllDialogs}>
        <DialogTitle>CREAR TARJETA</DialogTitle>
        <DialogContent>
          <FormControl variant="outlined" sx={{ m: 1, minWidth: 400 }}>
            <TextField
              id="create-text"
              label="PREGUNTA"
              variant="outlined"
              value={card?.text}
              onChange={e => {setCard({ ...card!, text: e.target.value }); fnErrorValidation()}}
              multiline
            /><br/>
            {answers.map((answer, index) => (
              <React.Fragment key={`answer-fragment-${index}`}>
                <TextField
                  key={`answer-${index}`}
                  // id={`create-answer-${answer.id}`}
                  label={`RESPUESTA ${index + 1}`}
                  value={answer.text}
                  onChange={(e) => fnUpdateAnswer(index, e.target.value)} // Modificar respuesta
                  multiline
                />
                <Button onClick={() => fnDeleteAnswer(index)} color="secondary">
                  Eliminar Respuesta
                </Button>
                <br />
              </React.Fragment>
            ))}
            <Button onClick={fnAddAnswer} startIcon={<AddCircleRoundedIcon />}>Agregar Respuesta</Button><br/>
            <FormControl fullWidth>
              <InputLabel id="select-totem-label">TOTEM</InputLabel>
              <Select
                labelId="select-totem-label"
                id="create-totem-id"
                value={card?.totem?.id || ''}
                onChange={(e) => {setCard({ ...card!, totem: { id: Number(e.target.value) } }); fnErrorValidation()}}
                error={!!errors.totem}
                required
              >
                {totems.map((totem, index) => (
                  <MenuItem key={index} value={totem.id}>
                    {totem.name}
                  </MenuItem>
                ))}
              </Select>
              {errors.totem && <p style={{ color: 'red' }}>{errors.totem}</p>}
            </FormControl><br/>
            <FormControl fullWidth>
              <InputLabel id="select-heritage-label">PATRIMONIO</InputLabel>
              <Select
                labelId="select-heritage-label"
                id="create-heritage-id"
                value={card?.heritage?.id || ''}
                onChange={(e) => {setCard({ ...card!, heritage: { id: Number(e.target.value) } }); fnErrorValidation()}}
                error={!!errors.heritage}
                required
              >
                {heritages.map((heritage, index) => (
                  <MenuItem key={index} value={heritage.id}>
                    {heritage.name}
                  </MenuItem>
                ))}
              </Select>
              {errors.heritage && <p style={{ color: 'red' }}>{errors.heritage}</p>}
            </FormControl><br/>
            <TextField
              id="create-qr-link"
              label="LINK DEL VIDEO"
              variant="outlined"
              value={card?.videoLink}
              onChange={e => {setCard({ ...card!, videoLink: e.target.value }); fnErrorValidation()}}
              multiline
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={fnCrear}>Crear</Button>
          <Button onClick={fnCloseAllDialogs}>Cancelar</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openDialogModificar} onClose={fnCloseAllDialogs}>
        <DialogTitle>MODIFICAR</DialogTitle>
        <DialogContent>
          {card && card.id > 0 ? (
            <FormControl variant="standard" sx={{ m: 1, minWidth: 400 }}>
              <TextField
                disabled
                id="outlined-disabled"
                label="ID"
                value={card.id}
              /><br/>
              <TextField
                label="PREGUNTA DE LA TARJETA"
                value={card.text}
                onChange={e => setCard({ ...card, text: e.target.value })}
                multiline
              /><br/>
              {answers.map((answer, index) => (
                <React.Fragment key={`answer-fragment-${index}`}>
                  <TextField
                    key={`answer-${index}`}
                    // id={`create-answer-${answer.id}`}
                    label={`RESPUESTA ${index + 1}`}
                    value={answer.text}
                    onChange={(e) => fnUpdateAnswer(index, e.target.value)} // Modificar respuesta
                    multiline
                  />
                  <Button onClick={() => fnDeleteAnswer(index)} color="secondary">
                    Eliminar Respuesta
                  </Button>
                  <br />
                </React.Fragment>
              ))}
              <Button onClick={fnAddAnswer} startIcon={<AddCircleRoundedIcon />}>Agregar Respuesta</Button><br/>
              <FormControl fullWidth>
                <InputLabel id="select-totem-label">TOTEM</InputLabel>
                <Select
                  labelId="select-totem-label"
                  id="create-totem-id"
                  value={card?.totem?.id || ''}
                  onChange={(e) => {setCard({ ...card!, totem: { id: Number(e.target.value) } }); fnErrorValidation()}}
                  error={!!errors.totem}
                  required
                >
                  {totems.map((totem, index) => (
                    <MenuItem key={index} value={totem.id}>
                      {totem.name}
                    </MenuItem>
                  ))}
                </Select>
                {errors.totem && <p style={{ color: 'red' }}>{errors.totem}</p>}
              </FormControl><br/>
              <FormControl fullWidth>
                <InputLabel id="select-heritage-label">PATRIMONIO</InputLabel>
                <Select
                  labelId="select-heritage-label"
                  id="create-heritage-id"
                  value={card?.heritage?.id || ''}
                  onChange={(e) => {setCard({ ...card!, heritage: { id: Number(e.target.value) } }); fnErrorValidation()}}
                  error={!!errors.heritage}
                  required
                >
                  {heritages.map((heritage, index) => (
                    <MenuItem key={index} value={heritage.id}>
                      {heritage.name}
                    </MenuItem>
                  ))}
                </Select>
                {errors.heritage && <p style={{ color: 'red' }}>{errors.heritage}</p>}
              </FormControl><br/>
              <TextField
                id="outlined-disabled"
                label="LINK DEL VIDEO"
                value={card.videoLink}
                onChange={e => setCard({ ...card, videoLink: e.target.value })}
                multiline
              />
            </FormControl>
          ) : (
            <DialogContentText>
              Cargando...
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={fnModificar}>Guardar</Button>
          <Button onClick={fnCloseAllDialogs}>Cancelar</Button>
        </DialogActions>
      </Dialog>

      {/* <Dialog open={openDialogCrearCurso} onClose={fnCloseAllDialogs}>
        <DialogTitle>CREAR CURSO</DialogTitle>
        <DialogContent>
          <FormControl variant="standard" sx={{ m: 1, minWidth: 300 }}>
            <TextField
              autoFocus
              margin="dense"
              id="temporada"
              label="Temporada"
              type="text"
              fullWidth
              variant="standard"
              onChange={e => setTemporada(Number(e.target.value))}
            />
            <TextField
              autoFocus
              margin="dense"
              id="letra"
              label="Letra"
              type="text"
              fullWidth
              variant="standard"
              onChange={e => setLetra(e.target.value)}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={fnCrearCurso}>Crear</Button>
          <Button onClick={fnCloseAllDialogs}>Cancelar</Button>
        </DialogActions>
      </Dialog> */}
      <Dialog open={openDialogVerQR} onClose={fnCloseAllDialogs}>
        <DialogTitle>QR</DialogTitle>
        <DialogContent>
          <div id="qrcode" ref={qrCodeRef}>
            {url && (
              <QRCodeCanvas
                value={url}  // Usamos QRCodeCanvas en lugar de QRCodeSVG
                size={256}
                bgColor={"#ffffff"}
                fgColor={"#000000"}
                level={"L"}
              />
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDownload} variant="contained">Descargar PNG</Button>
          <Button onClick={fnCloseAllDialogs}>Cerrar</Button>
        </DialogActions>
      </Dialog>
      {/* <Dialog open={openDialogListarEstudiantes} onClose={fnCloseAllDialogs} maxWidth="lg">
        <DialogTitle>MODIFICAR CURSO</DialogTitle>
        <DialogContent>
          <Box display="flex" justifyContent="space-between">
            <Box sx={{
              minWidth: '500px', // Ancho fijo
              height: '500px', // Alto fijo
              border: '0px solid black', // Borde para visualización
              display: 'flex', // Mostrar los elementos internos en una fila (por defecto)
              flexDirection: 'column', // Cambiar a columna para alinear títulos arriba
            }} padding="16px" flex="1">
              <Typography variant="h6">Alumnos del Curso</Typography>
              <List>
                {estudiantescard.map((estudiante: Totem) => (
                  <ListItem key={`${estudiante.id}+_a`}>
                    <Button variant="contained" size="small" onClick={() => moverEstudianteCursoAColegio(estudiante)} sx={{ marginRight: '8px' }}>
                      <PersonRemoveIcon sx={{ marginLeft: '0px' }} />
                    </Button>
                    <ListItemText primary={`${estudiante.id} ${estudiante.name}`}/>
                  </ListItem>
                ))}
              </List>
            </Box>
            <Divider orientation="vertical" flexItem />
            <Box sx={{
              minWidth: '500px', // Ancho fijo
              height: '500px', // Alto fijo
              border: '0px solid black', // Borde para visualización
              display: 'flex', // Mostrar los elementos internos en una fila (por defecto)
              flexDirection: 'column', // Cambiar a columna para alinear títulos arriba
            }} padding="16px" flex="1">
              <Typography variant="h6">Alumnos Disponibles</Typography>
              <List>
                {estudiantes.map((estudiante: Totem) => (
                  <ListItem key={`${estudiante.id}+_b`}>
                    <Button variant="contained" size="small" onClick={() => moverEstudianteColegioACurso(estudiante)} sx={{ marginRight: '8px' }}>
                      <PersonAddIcon sx={{ marginLeft: '0px' }} />
                    </Button>
                    <ListItemText primary={`${estudiante.id} ${estudiante.name}`}/>
                  </ListItem>
                ))}
              </List>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={fnGuardarCurso}>Guardar Cambios</Button>
          <Button onClick={fnCloseAllDialogs}>Cancelar</Button>
        </DialogActions>
      </Dialog> */}
      <div className="container">
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">ID</TableCell>
                <TableCell align="center">PREGUNTA</TableCell>
                <TableCell align="center">TOTEM</TableCell>
                <TableCell align="right"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {cards.map((card: Card) => {
                if (card.totem!.name !== previousTotem) {
                  previousTotem = card.totem!.name!;
                  
                  if (rowBackgroundColor === '#ffffff') {
                    rowBackgroundColor = '#f0f0f0';
                  } else {
                    rowBackgroundColor = '#ffffff';
                  }
                }

                return (
                  <TableRow key={card.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} style={{ backgroundColor: rowBackgroundColor }}>
                    <TableCell align="center" component="th" scope="row">
                      {card.id}
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                      {card.text}
                    </TableCell>
                    <TableCell align="center">
                      {card.totem?.name}
                    </TableCell>
                    <TableCell align="right">
                      <Button variant="contained" size="small" startIcon={<PreviewRoundedIcon />} onClick={() => fnOpenDialogVerQR(card.id)} >QR</Button>&nbsp;
                      <Button variant="contained" size="small" startIcon={<PreviewRoundedIcon />} onClick={() => fnOpenDialogVerDetalle(card.id)} >Detalles</Button>&nbsp;
                      <Button variant="contained" size="small" startIcon={<SettingsRoundedIcon />} onClick={() => fnOpenDialogModificar(card.id)}>Modificar</Button>&nbsp;
                      <Button variant="contained" size="small" startIcon={<DeleteForeverRoundedIcon />} onClick={ () => fnDelete(card.id) }>Eliminar</Button>
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
            <TableFooter>
              {/* <TableRow>
                <TableCell align="center" colSpan={3}>
                  <div className='pagination-container'>
                    <Stack spacing={5}>
                      <Pagination count={10} shape="rounded" page={1} />
                    </Stack>
                  </div>
                </TableCell>
              </TableRow> */}
            </TableFooter>
          </Table>
        </TableContainer>
      </div>
    </section>
  );
};

const Cards = () => {
  return (
    <Page breadcrumbs={breadcrumbs} children={<Content />} />
  );
};

export default Cards;
