import axios from 'axios';

import { GLOBAL_VARS } from "../config/global-vars.config";
import { Heritage } from './heritages.service';
import { Card } from './cards.service';

export interface Totem {
  id: number; // INT AUTO_INCREMENT
  name?: string; // VARCHAR(255)
  qrLink?: string; // VARCHAR(500)
  message?: string; // VARCHAR(1000)
  communeId?: string;
  communeName?: string;
  image?: Buffer; // MEDIUMBLOB
  imageBase64?: string; // Base64 para uso en frontend
};

export interface Commune {
  id: string; // VARCHAR(10)
  name?: string; // VARCHAR(100)
}

// Configuración de Axios
axios.defaults.baseURL = GLOBAL_VARS.API;

axios.interceptors.request.use((config) => {
  const token = sessionStorage.getItem('token');
  config.headers['Authorization'] = token;

  return config;
});

class TotemsService {
  static async comunas() {
    try {
      const response = await axios.get<Commune[]>(`/communes`);

      return response.data;
    } catch (e) {
      console.error(e);
      return [];
    }
  };

  static async listar() {
    try {
      const response = await axios.get<Totem[]>(`/totems`);

      return response.data;
    } catch (e) {
      console.error(e);
      return [];
    }
  };

  static async obtener(id: number) {
    try {
      const response = await axios.get<Totem>(`/totems/${id}`);

      return response.data;
    } catch (e) {
      console.error(e);
      return undefined;
    }
  };

  static async crear(totem: Totem, file: File) {
    try {
      const formData = new FormData();

      if (totem.name) {
        formData.append('name', totem.name);
      }

      if (totem.qrLink) {
        formData.append('qrLink', totem.qrLink);
      }

      if (totem.message) {
        formData.append('message', totem.message);
      }

      if (totem.communeId) {
        formData.append('communeId', totem.communeId);
      }

      if (file) {
        formData.append('image', file);
      }

      const response = await axios.post('/totems', formData, { headers: {} });

      if (response.status === 201) {
        return response.data;
      } else {
        return undefined;
      }
    } catch (e) {
      console.error(e);
    }
  }

  static async cargar(file: File | null) {
    try {
      if (!file) {
        console.error("No file provided for upload");
        return false;
      }

      const data = new FormData();
      data.append('file', file);

      const response = await axios.post(`/totems/load`, data, { headers: {} });

      if (response.status === 201) {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      console.error(e);
    }
  }

  static async descargar() {
    try {
      const response = await axios.get(`/totems/download-file`, {
        responseType: 'blob', // Esto indica que esperas una respuesta binaria (archivo)
      });

      return response;
    } catch (e) {
      console.error(e)
    }
  }

  static async eliminar(id: number) {
    try {
      const response = await axios.delete(`/totems/${id}`);

      if (response.status === 204) {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  static async actualizar(totem: Totem, file: File) {
    try {
      const formData = new FormData();

      if (totem.name) {
        formData.append('name', totem.name);
      }

      if (totem.qrLink) {
        formData.append('qrLink', totem.qrLink);
      }

      if (totem.message) {
        formData.append('message', totem.message);
      }

      if (totem.communeId) {
        formData.append('communeId', totem.communeId);
      }

      if (file) {
        formData.append('image', file);
      }

      const response = await axios.patch(`/totems/${totem.id}`, formData, { headers: {} });

      if (response.status === 201) {
        return response.data;
      } else {
        return undefined;
      }
    } catch (e) {
      console.error(e)
    }
  }
}

export default TotemsService;
