import { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { QRCodeCanvas } from 'qrcode.react';

import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Paper, TableFooter, Stack, Pagination, Dialog, DialogTitle, DialogContent, DialogContentText, TextField, DialogActions, FormControl, InputLabel, Input, Select, MenuItem } from "@mui/material";

import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import PreviewRoundedIcon from '@mui/icons-material/PreviewRounded';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';

import Page from '../components/page/page.component';
import TotemsService, { Totem, Commune } from '../services/totems.service';

import './totems.page.css';

const breadcrumbs = [
  <Link key="1" to="/">Inicio</Link>,
  <Typography key="2" color="text.primary">Totems</Typography>
];

const Content = () => {
  let previousCommune = '';
  let rowBackgroundColor = '#ffffff'
  const [refresh, setRefresh] = useState<number>(0);
  const [openDialogModificar, setOpenDialogModificar] = useState(false);
  const [url, setUrl] = useState<string>('');
  
  const [id, setId] = useState<number>(0);
  const [actionGet, setActionGet] = useState<boolean>(false);
  const [actionCreate, setActionCreate] = useState<boolean>(false);
  const [actionUpdate, setActionUpdate] = useState<boolean>(false);
  const [actionDelete, setActionDelete] = useState<boolean>(false);
  const [totems, setTotems] = useState<Totem[]>([]);
  const [communes, setCommunes] = useState<Commune[]>([]);
  const [totem, setTotem] = useState<Totem | undefined>();
  const [file, setFile] = useState<File | null>(null);
  const [errors, setErrors] = useState({
    name: '',
    qrLink: '',
    message: '',
    communeId: '',
    image: ''
  });
  const [openDialogVerQR, setOpenDialogVerQR] = useState(false);
  const [openDialogVerDetalle, setOpenDialogVerDetalle] = useState(false);
  const [openDialogCrear, setOpenDialogCrear] = useState(false);

  const qrCodeRef = useRef<HTMLDivElement>(null);

  const handleDownload = () => {
    if (qrCodeRef.current) {
      const canvas = qrCodeRef.current.querySelector('canvas');
      if (canvas) {
        const totemId = url.split('/').pop();
        const imageName = `qr-code-totem-id-${totemId}.png`;
        const imgURL = canvas.toDataURL('image/png');  // Convertir el canvas a una URL de datos en PNG
        const link = document.createElement('a');
        link.href = imgURL;
        // link.download = 'qr-code.png';  // Nombre del archivo que se descargará
        link.download = imageName;  // Nombre del archivo que se descargará
        link.click();  // Simular el clic para iniciar la descarga
      }
    }
  };

  const fnOpenDialogVerQR = (link: string) => {
    setUrl(link);  // Guardamos la URL en el estado
    setOpenDialogVerQR(true);  // Abrimos el modal
  };
  
  const fnOpenDialogVerDetalle = (id: number) => {
    setId(id);
    setActionGet(true);
    setOpenDialogVerDetalle(true);
  };
  
  const fnOpenDialogCrear = () => {
    setTotem(undefined);
    setOpenDialogCrear(true);
  };

  const fnOpenDialogModificar = (id: number) => {
    let newErrors = { name: '', qrLink: '', message: '', communeId: '', image: '' };
    setErrors(newErrors);
    setOpenDialogModificar(true);
    setId(id);
    setActionGet(true);
  };

  const fnErrorValidation = () => {
    let valid = true;
    let newErrors = { name: '', qrLink: '', message: '', communeId: '', image: '' };
    
    if (totem?.name === '') {
      newErrors.name = 'El nombre es requerido';
      valid = false;
    } else {
      newErrors.name = '';
    }

    if (totem?.qrLink === '') {
      newErrors.qrLink = 'El link del QR es requerido';
      valid = false;
    } else {
      newErrors.qrLink = '';
    }

    if (totem?.message === '') {
      newErrors.message = 'El mensaje es requerido';
      valid = false;
    } else {
      newErrors.message = '';
    }

    if (totem?.communeId === null) {
      newErrors.image = 'Debe seleccionar una comuna';
      valid = false;
    } else {
      newErrors.image = '';
    }

    if (file === null) {
      newErrors.image = 'Debe seleccionar una imagen';
      valid = false;
    } else {
      newErrors.image = '';
    }

    setErrors(newErrors);
    return valid;
  };

  const fnCrear = () => {
    if (fnErrorValidation()) {
      setActionCreate(true);
    }
  };

  const fnModificar = () => {
    setActionUpdate(true);
  };

  const fnDelete = (id: number) => {
    setId(id);
    setActionDelete(true);
  };
  
  const fnCloseAllDialogs = () => {
    setOpenDialogVerQR(false);
    setOpenDialogVerDetalle(false);
    setOpenDialogCrear(false);
    setOpenDialogModificar(false);
  };

  useEffect(() => {
    (async () => {
      if (actionCreate) {
        const data = await TotemsService.crear(totem!, file!);
        setTotem(data);
        setActionCreate(false);
        setOpenDialogCrear(false);
        setRefresh((refresh) => refresh + 1);
      }
    })();
  }, [actionCreate]);

  useEffect(() => {
    (async () => {
      if (actionGet) {
        const data = await TotemsService.obtener(id);
        setTotem(data);
        setActionGet(false);
        setId(0);
      }
    })();
  }, [actionGet]);

  useEffect(() => {
    (async () => {
      if (actionUpdate) {
        const data = await TotemsService.actualizar(totem!, file!);
        setTotem(data);
        setActionUpdate(false);
        setOpenDialogModificar(false);
        setRefresh((refresh) => refresh + 1);
      }
    })();
  }, [actionUpdate]);

  useEffect(() => {
    (async () => {
      if (actionDelete) {
        const data = await TotemsService.eliminar(id);
        setActionDelete(false);
        setRefresh((refresh) => refresh + 1);
      }
    })();
  }, [actionDelete]);
  
  useEffect(() => {
    (async () => {
      const dataTotems = await TotemsService.listar();
      setTotems(dataTotems);
      const dataComunes = await TotemsService.comunas();
      setCommunes(dataComunes);
    })();
  }, [refresh]);

  return (
    <section>
      <Button variant="contained" size="small" startIcon={<AddCircleRoundedIcon />} onClick={fnOpenDialogCrear}>Crear</Button>&nbsp;
      
      <Dialog open={openDialogVerDetalle} onClose={fnCloseAllDialogs}>
        <DialogTitle>DETALLES</DialogTitle>
        <DialogContent>
          {totem ? (
            <FormControl variant="outlined" sx={{ m: 1, minWidth: 400 }}>
              <TextField
                disabled
                id="detail-id"
                label="ID"
                value={totem?.id}
                className="disabled-textfield"
              /><br/>
              <TextField
                disabled
                id="detail-name"
                label="NOMBRE"
                value={totem?.name}
                className="disabled-textfield"
                multiline
              /><br/>
              <TextField
                disabled
                id="detail-qrlink"
                label="QR LINK"
                value={totem?.qrLink}
                className="disabled-textfield"
              /><br/>
              <TextField
                disabled
                id="detail-message"
                label="MENSAJE"
                value={totem?.message}
                className="disabled-textfield"
                multiline
              /><br/>
              <TextField
                disabled
                id="detail-commune-name"
                label="COMUNA"
                value={totem?.communeName}
                className="disabled-textfield"
              /><br/>
              <img
                src={totem.imageBase64}
                alt="IMAGEN DE TOTEM"
                style={{ maxWidth: '100%', height: 'auto' }}
              />
            </FormControl>
          ) : (
            <DialogContentText>
              Cargando...
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={fnCloseAllDialogs}>Cancelar</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openDialogVerQR} onClose={fnCloseAllDialogs}>
        <DialogTitle>QR</DialogTitle>
        <DialogContent>
          <div id="qrcode" ref={qrCodeRef}>
            {url && (
              <QRCodeCanvas
                value={url}  // Usamos QRCodeCanvas en lugar de QRCodeSVG
                size={256}
                bgColor={"#ffffff"}
                fgColor={"#000000"}
                level={"L"}
              />
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDownload} variant="contained">Descargar PNG</Button>
          <Button onClick={fnCloseAllDialogs}>Cerrar</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openDialogCrear} onClose={fnCloseAllDialogs}>
        <DialogTitle>CREAR TOTEM</DialogTitle>
        <DialogContent>
          <FormControl variant="standard" sx={{ m: 1, minWidth: 400 }}>
            <TextField
              id="create-name"
              label="NOMBRE"
              value={totem?.name}
              onChange={e => {setTotem({ ...totem!, name: e.target.value }); fnErrorValidation()}}
              className="disabled-textfield"
              error={!!errors.name}
              helperText={errors.name}
              required
              multiline
            /><br/>
            <TextField
              id="create-qrlink"
              label="QR LINK"
              value={totem?.qrLink}
              onChange={e => {setTotem({ ...totem!, qrLink: e.target.value }); fnErrorValidation()}}
              className="disabled-textfield"
              error={!!errors.qrLink}
              helperText={errors.qrLink}
              required
            /><br/>
            <TextField
              id="create-message"
              label="MENSAJE"
              value={totem?.message}
              onChange={e => {setTotem({ ...totem!, message: e.target.value }); fnErrorValidation()}}
              className="disabled-textfield"
              error={!!errors.message}
              helperText={errors.message}
              required
              multiline
            /><br/>
            <FormControl fullWidth>
              <InputLabel id="select-commune-label">COMUNA</InputLabel>
              <Select
                labelId="select-commune-label"
                id="create-commune-id"
                value={totem?.communeId || ''}
                onChange={(e) => {setTotem({ ...totem!, communeId: e.target.value }); fnErrorValidation()}}
                error={!!errors.communeId}
                required
              >
                {communes.map((commune, index) => (
                  <MenuItem key={index} value={commune.id}>
                    {commune.name}
                  </MenuItem>
                ))}
              </Select>
              {errors.communeId && <p style={{ color: 'red' }}>{errors.communeId}</p>}
            </FormControl><br/>
            <input
              accept="image/*"
              style={{ display: 'none' }} // Ocultar el input file
              id="file-upload"
              type="file"
              onChange={(e) => {
                if (e.target.files && e.target.files[0]) {
                  setFile(e.target.files[0]); // Guarda el archivo en el estado
                } else {
                  setFile(null); // Si no hay archivo, setea el estado a null
                }

                fnErrorValidation();
              }}
            />
            <label htmlFor="file-upload">
              <Button variant="contained" component="span">
                Adjuntar Imagen
              </Button>
              {errors.image && errors.image.length > 0 && <p style={{ color: 'red' }}>{errors.image}</p>}
            </label>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={fnCrear}>Crear</Button>
          <Button onClick={fnCloseAllDialogs}>Cancelar</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openDialogModificar} onClose={fnCloseAllDialogs}>
        <DialogTitle>MODIFICAR TOTEM</DialogTitle>
        <DialogContent>
          {totem ? (
            <FormControl variant="outlined" sx={{ m: 1, minWidth: 400 }}>
              <TextField
                id="modify-name"
                label="NOMBRE"
                variant="outlined"
                value={totem?.name}
                onChange={e => {setTotem({ ...totem!, name: e.target.value }); fnErrorValidation()}}
                className="disabled-textfield"
                error={!!errors.name}
                helperText={errors.name}
                required
                multiline
              /><br/>
              <TextField
                id="modify-qrlink"
                label="QR LINK"
                variant="outlined"
                value={totem?.qrLink}
                onChange={e => {setTotem({ ...totem!, qrLink: e.target.value }); fnErrorValidation()}}
                className="disabled-textfield"
                error={!!errors.qrLink}
                helperText={errors.qrLink}
                required
              /><br/>
              <TextField
                id="modify-message"
                label="MENSAJE"
                variant="outlined"
                value={totem?.message}
                onChange={e => {setTotem({ ...totem!, message: e.target.value }); fnErrorValidation()}}
                className="disabled-textfield"
                error={!!errors.message}
                helperText={errors.message}
                required
                multiline
              /><br/>
              <FormControl fullWidth>
                <InputLabel id="select-commune-label" variant="outlined">COMUNA</InputLabel>
                <Select
                  labelId="select-commune-label"
                  id="modify-commune-id"
                  variant="outlined"
                  value={totem?.communeId || ''}
                  onChange={(e) => setTotem({ ...totem!, communeId: e.target.value })}
                  error={!!errors.communeId}
                  required
                >
                  {communes.map((commune, index) => (
                    <MenuItem key={index} value={commune.id}>
                      {commune.name}
                    </MenuItem>
                  ))}
                </Select>
                {errors.communeId && <p style={{ color: 'red' }}>{errors.communeId}</p>}
              </FormControl><br/>
              <input
                accept="image/*"
                style={{ display: 'none' }} // Ocultar el input file
                id="file-upload"
                type="file"
                onChange={(e) => {
                  if (e.target.files && e.target.files[0]) {
                    setFile(e.target.files[0]); // Guarda el archivo en el estado
                  } else {
                    setFile(null); // Si no hay archivo, setea el estado a null
                  }

                  fnErrorValidation();
                }}
              />
              <label htmlFor="file-upload">
                <Button variant="contained" component="span">
                  Adjuntar Imagen
                </Button>
              </label>
            </FormControl>
          ) : (
            <DialogContentText>
              Cargando...
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={fnModificar}>Guardar</Button>
          <Button onClick={fnCloseAllDialogs}>Cancelar</Button>
        </DialogActions>
      </Dialog>
      <div className="container">
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">ID</TableCell>
                <TableCell align="center">NOMBRE</TableCell>
                <TableCell align="center">COMUNA</TableCell>
                <TableCell align="right"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {totems.map((totem: Totem) => {
                if (totem.communeName! !== previousCommune) {
                  previousCommune = totem.communeName!;
                  
                  if (rowBackgroundColor === '#ffffff') {
                    rowBackgroundColor = '#f0f0f0';
                  } else {
                    rowBackgroundColor = '#ffffff';
                  }
                }

                return (
                  <TableRow key={totem.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} style={{ backgroundColor: rowBackgroundColor }}>
                    <TableCell align="center">{totem.id}</TableCell>
                    <TableCell align="center">{totem.name}</TableCell>
                    <TableCell align="center">{totem.communeName}</TableCell>
                    <TableCell align="right">
                      <Button variant="contained" size="small" startIcon={<PreviewRoundedIcon />} onClick={() => fnOpenDialogVerQR(totem.qrLink!)}>QR</Button>&nbsp;
                      <Button variant="contained" size="small" startIcon={<InfoRoundedIcon />} onClick={() => fnOpenDialogVerDetalle(totem.id)}>Detalles</Button>&nbsp;
                      <Button variant="contained" size="small" startIcon={<SettingsRoundedIcon />} onClick={() => fnOpenDialogModificar(totem.id)}>Modificar</Button>&nbsp;
                      <Button variant="contained" size="small" startIcon={<DeleteForeverRoundedIcon />} onClick={() => fnDelete(totem.id)}>Eliminar</Button>
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
            <TableFooter></TableFooter>
          </Table>
        </TableContainer>
      </div>
    </section>
  );
};

const Totems = () => {
  return (
    <Page breadcrumbs={breadcrumbs} children={<Content />} />
  );
};

export default Totems;
