import { Routes, Route } from "react-router-dom";

import Home from "../pages/home.page";
import Login from "../pages/login.page";
import Users from "../pages/users.page";
import Heritages from "../pages/heritages.page";
import Totems from "../pages/totems.page";
import Cards from "../pages/cards.page";

const Router = () => {
  return (
    <Routes>
      <Route
        path="/login"
        element={<Login />}
      />
      <Route
        path="/"
        element={<Home />}
      />
      <Route
        path="/users"
        element={<Users />}
      />
      <Route
        path="/heritages"
        element={<Heritages />}
      />
      <Route
        path="/totems"
        element={<Totems />}
      />
      <Route
        path="/cards"
        element={<Cards />}
      />
    </Routes>
  );
};

export default Router;
