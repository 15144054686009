import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Paper, TableFooter, Stack, Pagination, Dialog, DialogTitle, DialogContent, DialogContentText, TextField, DialogActions, FormControl } from "@mui/material";

import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import PreviewRoundedIcon from '@mui/icons-material/PreviewRounded';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';

import Page from '../components/page/page.component';
import HeritagesService, { Heritage } from '../services/heritages.service';

import './heritages.page.css';

const breadcrumbs = [
  <Link key="1" to="/">Inicio</Link>,
  <Typography key="2" color="text.primary">Patrimonios</Typography>
];

const Content = () => {
  const [refresh, setRefresh] = useState<number>(0);
  const [openDialogCrearColegio, setOpenDialogCrearColegio] = useState(false);
  const [openDialogModificarColegio, setOpenDialogModificarColegio] = useState(false);
  const [createId, setCreateId] = useState<string>('');
  const [nombreColegio, setNombreColegio] = useState<string>('');
  const [deleteId, setDeleteId] = useState<number | undefined>();
  const [updateId, setUpdateId] = useState<number | undefined>();
  
  const [id, setId] = useState<number>(0);
  const [actionGet, setActionGet] = useState<boolean>(false);
  const [actionCreate, setActionCreate] = useState<boolean>(false);
  const [actionUpdate, setActionUpdate] = useState<boolean>(false);
  const [actionDelete, setActionDelete] = useState<boolean>(false);
  const [heritages, setHeritages] = useState<Heritage[]>([]);
  const [heritage, setHeritage] = useState<Heritage | undefined>();
  const [file, setFile] = useState<File | null>(null);
  const [errors, setErrors] = useState({
    name: '',
    backgroundColor: '',
    image: '',
  });
  const [openDialogCrear, setOpenDialogCrear] = useState(false);
  const [openDialogVerDetalle, setOpenDialogVerDetalle] = useState(false);
  const [openDialogModificar, setOpenDialogModificar] = useState(false);
  const [openDialogEliminar, setOpenDialogEliminar] = useState(false);

  const fnOpenDialogCrear = () => {
    let newErrors = { name: '', backgroundColor: '', image: '' };
    const newHeritage: Heritage = {
      id: 0,
      name: '',
      backgroundColor: '',
      imageBase64: ''
    };
    setErrors(newErrors);
    setHeritage(newHeritage);
    setFile(null);
    setOpenDialogCrear(true);
  };
  
  const fnOpenDialogVerDetalle = (id: number) => {
    setId(id);
    setActionGet(true);
    setOpenDialogVerDetalle(true);
  };

  const fnOpenDialogModificar = (id: number) => {
    let newErrors = { name: '', backgroundColor: '', image: '' };
    setErrors(newErrors);
    setId(id);
    setActionGet(true);
    setOpenDialogModificar(true);
  };

  const fnErrorValidation = () => {
    let valid = true;
    let newErrors = { name: '', backgroundColor: '', image: '' };
    
    if (heritage?.name === '') {
      newErrors.name = 'El nombre es requerido';
      valid = false;
    } else {
      newErrors.name = '';
    }

    if (heritage?.backgroundColor === '') {
      newErrors.backgroundColor = 'El color de fondo es requerido';
      valid = false;
    } else {
      newErrors.backgroundColor = '';
    }

    if (file === null) {
      newErrors.image = 'Debe seleccionar una imagen';
      valid = false;
    } else {
      newErrors.image = '';
    }

    setErrors(newErrors);
    return valid;
  };

  const fnCrear = () => {
    if (fnErrorValidation()) {
      setActionCreate(true);
    }
  };

  const fnModificar = () => {
    setActionUpdate(true);
  };

  const fnDelete = (id: number) => {
    setId(id);
    setActionDelete(true);
  }

  // const fnCrearColegio = () => {
  //   setUpdateId('');
  //   setDeleteId('');

  //   const colegio: Heritage = {
  //     id: createId,
  //     nombre: nombreColegio
  //   };
    
  //   setColegio(colegio);
  //   fnCloseAllDialogs();
  // };
  
  // const fnModificarColegio = () => {
  //   setCreateId('');
  //   setDeleteId('');

  //   const colegio: Heritage = {
  //     id: updateId,
  //     nombre: nombreColegio
  //   };
    
  //   setColegio(colegio);
  //   fnCloseAllDialogs();
  // };
  
  const fnCloseAllDialogs = () => {
    setOpenDialogVerDetalle(false);
    setOpenDialogCrear(false);
    setOpenDialogModificar(false);
  };

  // useEffect(() => {
  //   if (deleteId !== '') {
  //     (async () => {
  //       const response = await HeritagesService.eliminar(deleteId);
  //       setDeleteId('');
  //       setRefresh((refresh) => refresh + 1);
  //     })();
  //   }

  //   if (typeof colegio === 'object') {
  //     if (createId !== '') {
  //       (async () => {
  //         const response = await HeritagesService.crear(colegio);
  //         setRefresh((refresh) => refresh + 1);
  //       })();

  //       setCreateId('');
  //       setNombreColegio('');
  //     }
      
  //     if (updateId !== '') {
  //       (async () => {
  //         const response = await HeritagesService.actualizar(colegio);
  //         setRefresh((refresh) => refresh + 1);
  //       })();

  //       setUpdateId('');
  //     }

  //     setColegio(undefined);
  //   }
  // }, [colegio, deleteId]);

  useEffect(() => {
    (async () => {
      if (actionCreate) {
        const data = await HeritagesService.crear(heritage!, file!);
        setHeritage(data);
        setActionCreate(false);
        setOpenDialogCrear(false);
        setRefresh((refresh) => refresh + 1);
      }
    })();
  }, [actionCreate]);
  
  useEffect(() => {
    (async () => {
      if (actionGet) {
        const data = await HeritagesService.obtener(id);
        setHeritage(data);
        setActionGet(false);
        setId(0);
      }
    })();
  }, [actionGet]);

  useEffect(() => {
    (async () => {
      if (actionUpdate) {
        const data = await HeritagesService.actualizar(heritage!, file!);
        setHeritage(data);
        setActionUpdate(false);
        setOpenDialogModificar(false);
        setRefresh((refresh) => refresh + 1);
      }
    })();
  }, [actionUpdate]);

  useEffect(() => {
    (async () => {
      if (actionDelete) {
        const data = await HeritagesService.eliminar(id);
        setActionDelete(false);
        setRefresh((refresh) => refresh + 1);
      }
    })();
  }, [actionDelete]);
  
  useEffect(() => {
    (async () => {
      const data = await HeritagesService.listar();
      setHeritages(data);
    })();
  }, [refresh]);
  
  return (
    <section>
      <Button variant="contained" size="small" startIcon={<AddCircleRoundedIcon />} onClick={fnOpenDialogCrear} >Crear</Button>
      <Dialog open={openDialogVerDetalle} onClose={fnCloseAllDialogs}>
        <DialogTitle>DETALLES</DialogTitle>
        <DialogContent>
          {heritage ? (
            <FormControl variant="standard" sx={{ m: 1, minWidth: 400 }}>
              <TextField
                disabled
                id="detail-id"
                label="ID"
                value={heritage.id}
                className="disabled-textfield"
              /><br/>
              <TextField
                disabled
                id="detail-name"
                label="NOMBRE"
                value={heritage.name}
                className="disabled-textfield"
                multiline
              /><br/>
              <TextField
                disabled
                id="detail-background-color"
                label="COLOR DE FONDO"
                value={heritage.backgroundColor}
                className="disabled-textfield"
              /><br/>
              <img
                src={heritage.imageBase64}
                alt="IMAGEN DE PATRIMONIO"
                style={{ maxWidth: '100%', height: 'auto' }}
              />
            </FormControl>
          ) : (
            <DialogContentText>
              Cargando...
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={fnCloseAllDialogs}>Cancelar</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openDialogCrear} onClose={fnCloseAllDialogs}>
        <DialogTitle>CREAR PATRIMONIO</DialogTitle>
        <DialogContent>
          <FormControl variant="standard" sx={{ m: 1, minWidth: 400 }}>
            <TextField
              id="create-name"
              label="NOMBRE"
              value={heritage?.name}
              onChange={e => {setHeritage({ ...heritage!, name: e.target.value }); fnErrorValidation()}}
              className="disabled-textfield"
              error={!!errors.name}
              helperText={errors.name}
              required
              multiline
            /><br/>
            <TextField
              id="create-background-color"
              label="COLOR DE FONDO"
              value={heritage?.backgroundColor}
              onChange={e => {setHeritage({ ...heritage!, backgroundColor: e.target.value }); fnErrorValidation()}}
              className="disabled-textfield"
              error={!!errors.backgroundColor}
              helperText={errors.backgroundColor}
              required
            /><br/>
            <input
              accept="image/*"
              style={{ display: 'none' }} // Ocultar el input file
              id="file-upload"
              type="file"
              onChange={(e) => {
                if (e.target.files && e.target.files[0]) {
                  setFile(e.target.files[0]); // Guarda el archivo en el estado
                } else {
                  setFile(null); // Si no hay archivo, setea el estado a null
                }

                fnErrorValidation();
              }}
            />
            <label htmlFor="file-upload">
              <Button variant="contained" component="span">
                Adjuntar Imagen
              </Button>
              {errors.image && errors.image.length > 0 && <p style={{ color: 'red' }}>{errors.image}</p>}
            </label>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={fnCrear}>Crear</Button>
          <Button onClick={fnCloseAllDialogs}>Cancelar</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openDialogModificar} onClose={fnCloseAllDialogs}>
        <DialogTitle>MODIFICAR PATRIMONIO</DialogTitle>
        <DialogContent>
        <FormControl variant="standard" sx={{ m: 1, minWidth: 400 }}>
            <TextField
              id="modify-name"
              label="NOMBRE"
              value={heritage?.name}
              onChange={e => {setHeritage({ ...heritage!, name: e.target.value }); fnErrorValidation()}}
              className="disabled-textfield"
              error={!!errors.name}
              helperText={errors.name}
              required
              multiline
            /><br/>
            <TextField
              id="modify-background-color"
              label="COLOR DE FONDO"
              value={heritage?.backgroundColor}
              onChange={e => {setHeritage({ ...heritage!, backgroundColor: e.target.value }); fnErrorValidation()}}
              className="disabled-textfield"
              error={!!errors.backgroundColor}
              helperText={errors.backgroundColor}
              required
            /><br/>
            <input
              accept="image/*"
              style={{ display: 'none' }} // Ocultar el input file
              id="file-upload"
              type="file"
              onChange={(e) => {
                if (e.target.files && e.target.files[0]) {
                  setFile(e.target.files[0]); // Guarda el archivo en el estado
                } else {
                  setFile(null); // Si no hay archivo, setea el estado a null
                }

                fnErrorValidation();
              }}
            />
            <label htmlFor="file-upload">
              <Button variant="contained" component="span">
                Adjuntar Imagen
              </Button>
            </label>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={fnModificar}>Guardar</Button>
          <Button onClick={fnCloseAllDialogs}>Cancelar</Button>
        </DialogActions>
      </Dialog>

      <div className="container">
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">NOMBRE</TableCell>
                <TableCell align="center">COLOR</TableCell>
                <TableCell align="right"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {heritages.map((heritage: Heritage) => (
                <TableRow
                  key={`cole-${heritage.id}`}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell align="center" component="th" scope="row">
                    {heritage.name}
                  </TableCell>
                  <TableCell align="center" style={{ backgroundColor: heritage.backgroundColor, color: '#ffffff', fontWeight: 'bold' }}>{heritage.backgroundColor!}</TableCell>
                  <TableCell align="right">
                    <Button variant="contained" size="small" startIcon={<InfoRoundedIcon />} onClick={() => fnOpenDialogVerDetalle(heritage.id)}>Detalles</Button>&nbsp;
                    <Button variant="contained" size="small" startIcon={<SettingsRoundedIcon />} onClick={() => fnOpenDialogModificar(heritage.id)}>Modificar</Button>&nbsp;
                    <Button variant="contained" size="small" startIcon={<DeleteForeverRoundedIcon />} onClick={ () => fnDelete(heritage.id) }>Eliminar</Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
            </TableFooter>
          </Table>
        </TableContainer>
      </div>
    </section>
  );
};

const Heritages = () => {
  return (
    <Page breadcrumbs={breadcrumbs} children={<Content />} />
  );
};

export default Heritages;
