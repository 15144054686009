import axios from 'axios';

import { GLOBAL_VARS } from "../config/global-vars.config";

export interface Heritage {
  id: number; // INT AUTO_INCREMENT
  name?: string; // VARCHAR(100)
  image?: Buffer; // MEDIUMBLOB
  imageBase64?: string; // Base64 para uso en frontend
  backgroundColor?: string; // VARCHAR(7)
}

// Configuración de Axios
axios.defaults.baseURL = GLOBAL_VARS.API;

axios.interceptors.request.use((config) => {
  const token = sessionStorage.getItem('token');
  config.headers['Authorization'] = token;

  return config;
});

class HeritagesService {
  static async listar() {
    try {
      const response = await axios.get<Heritage[]>(`/heritages`);

      return response.data;
    } catch (e) {
      console.error(e);
      return [];
    }
  };

  static async obtener(id: number) {
    try {
      const response = await axios.get<Heritage>(`/heritages/${id}`);

      return response.data;
    } catch (e) {
      console.error(e);
      return undefined;
    }
  };

  static async crear(heritage: Heritage, file: File) {
    try {
      const formData = new FormData();

      if (heritage.name) {
        formData.append('name', heritage.name);
      }

      if (heritage.backgroundColor) {
        formData.append('backgroundColor', heritage.backgroundColor);
      }

      if (file) {
        formData.append('image', file);
      }

      const response = await axios.post('/heritages', formData, { headers: {} });

      if (response.status === 201) {
        return response.data;
      } else {
        return undefined;
      }
    } catch (e) {
      console.error(e);
    }
  }

  static async eliminar(id: number) {
    try {
      const response = await axios.delete(`/heritages/${id}`);

      if (response.status === 204) {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  static async actualizar(heritage: Heritage, file: File) {
    try {
      const formData = new FormData();

      if (heritage.name) {
        formData.append('name', heritage.name);
      }

      if (heritage.backgroundColor) {
        formData.append('backgroundColor', heritage.backgroundColor);
      }

      if (file) {
        formData.append('image', file);
      }

      const response = await axios.patch(`/heritages/${heritage.id}`, formData, { headers: {} });

      if (response.status === 201) {
        return response.data;
      } else {
        return undefined;
      }
    } catch (e) {
      console.error(e)
    }
  }
}

export default HeritagesService;
