import * as React from 'react';

import { Box, CssBaseline, Breadcrumbs, Toolbar, Typography } from '@mui/material';

import Menu, { DrawerHeader, AppBar } from '../menu/menu.component';

import './page.component.css';

const Page = ({children, breadcrumbs}: {children: React.ReactNode, breadcrumbs: React.ReactNode[]}) => {
  React.useEffect(() => {
    if (sessionStorage.getItem('token') === null) {
      window.location.href = '/login';
    }
  }, []);
  
  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Menu />
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
        {
          breadcrumbs.length > 0 && <Breadcrumbs separator="›" aria-label="breadcrumb">
          {breadcrumbs}
        </Breadcrumbs>
        }
        {children}
      </Box>
    </Box>
  );
}

export {
  Page as default
};
