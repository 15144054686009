import axios from 'axios';

import { GLOBAL_VARS } from "../config/global-vars.config";
import { Heritage } from './heritages.service';
import { Totem } from './totems.service';

export interface Answer {
  id?: number; // INT AUTO_INCREMENT
  text?: string; // VARCHAR(255)
  card?: Card;
  index?: number; // INT
}

export interface Card {
  // id?: string | undefined;
  // colegio?: Heritage | undefined;
  // temporada?: number;
  // letra?: string;
  // creado?: string;
  // activo?: number;
  id: number; // INT AUTO_INCREMENT
  text?: string; // VARCHAR(1000)
  totem?: Totem; // Objeto de la tabla totem en lugar de FK totem_id
  heritage?: Heritage; // Objeto de la tabla heritage en lugar de FK heritage_id
  videoLink?: string; // VARCHAR(500)
  answers?: Answer[]; // Array de objetos Answer
  active?: boolean; // TINYINT(1), usamos boolean en TypeScript
  createdAt?: Date; // TIMESTAMP, puede ser NULL
};

// Configuración de Axios
axios.defaults.baseURL = GLOBAL_VARS.API;

axios.interceptors.request.use((config) => {
  const token = sessionStorage.getItem('token');
  config.headers['Authorization'] = token;

  return config;
});

class CardsService {
  static async listar() {
    let cards: Card[] = [];

    try {
      const response = await axios.get<Card[]>(`/cards`);

      cards = response.data;
    } catch (e) {
      console.error(e);
    } finally {
      return cards;
    }
  };

  static async obtener(id: number) {
    try {
      const response = await axios.get<Card>(`/cards/${id}`);

      return response.data;
    } catch (e) {
      console.error(e);
      return { id: 0 };
    }
  };

  static async obtenerAlumnosDelCurso(cursoId: string | undefined) {
    try {
      const response = await axios.get<Totem[]>(`/cards/estudiantes/${cursoId}`);

      return response.data;
    } catch (e) {
      console.error(e);
      return [];
    }
  };

  static async obtenerAlumnosFueraDelCurso(cursoId: string | undefined) {
    try {
      const response = await axios.get<Totem[]>(`/cards/estudiantes/not-in/${cursoId}`);

      return response.data;
    } catch (e) {
      console.error(e);
      return [];
    }
  };

  static async crear(curso: Card | undefined) {
    try {
      const response = await axios.post(`/cards`, curso, { headers: {} });

      if (response.status === 201) {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      console.error(e);
    }
  }

  static async eliminar(id: number) {
    try {
      const response = await axios.delete(`/cards/${id}`);

      if (response.status === 204) {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      console.error(e);
    }
  }

  static async actualizar(curso: Card) {
    try {
      const response = await axios.patch(`/cards/${curso.id}`, curso, { headers: {} });

      if (response.status === 201) {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      console.error(e)
    }
  }

  static async actualizarEstudiantes(id: string, estudiantes: Totem[]) {
    try {
      const response = await axios.patch(`/cards/estudiantes/${id}`, estudiantes, { headers: {} });

      if (response.status === 201) {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      console.error(e)
    }
  }
}

export default CardsService;
